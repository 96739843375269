import Vue from 'vue'

Vue.filter('capitalize', (value) => {
  if (!value) return ''
  let arr = value.toString().split(' ')
  let capitalized_array = []
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('title', (value, replacer = '_') => {
  if (!value) return ''

  let arr = value.toString().split(replacer)
  let capitalized_array = []
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('truncate', (value, limit) => {
  return value.substring(0, limit)
})

Vue.filter('tailing', (value, tail) => {
  return value + tail
})

Vue.filter('time', (value, is24HrFormat = false) => {
  if (!value) { return 'NaN' }
  const date = new Date(Date.parse(value))
  let hours = date.getHours()
  const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
  if (!is24HrFormat) {
    const time = hours > 12 ? 'AM' : 'PM'
    hours = hours % 12 || 12
    return `${hours}:${min} ${time}`
  }
  return `${hours}:${min}`
})

Vue.filter('date', (value, fullDate = false) => {
  const timeString = String(value)
  const date = timeString.slice(8, 10).trim()
  const month = timeString.slice(4, 7).trim()
  const year = timeString.slice(11, 15)

  if (!fullDate) return `${date} ${month}`
  return `${date} ${month} ${year}`
})

Vue.filter('month', (val, showYear = true) => {
  const timeString = String(val)

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./
  if (!showYear) {
    return regx.exec(timeString)[1]
  }
  return `${regx.exec(timeString)[1]} ${regx.exec(timeString)[2]}`
})

Vue.filter('csv', (value) => {
  return value.join(', ')
})

Vue.filter('filter_tags', (value) => {
  return value.replace(/<\/?[^>]+(>|$)/g, '')
})

Vue.filter('k_formatter', (num) => {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
})

Vue.filter('local_time_string', (timestamp) => {
  if (!timestamp) { return 'NaN' }
  return new Date(timestamp).toLocaleString('en-GB')
})

Vue.filter('local_date', (timestamp) => {
  if (!timestamp) { return 'NaN' }
  return new Date(timestamp).toLocaleString('en-GB').split(', ')[0]
})

Vue.filter('float_rounded', (value, digits) => {
  return Number(value).toFixed(digits)
})
