<template>
    <div>
        <b-row v-for="(item, index) in items" :key="(item[id_field] || item._id) + index" class="justify-content-center mb-1">
            <b-col cols="5">
                <span>{{ title }}</span>
                <multi-select v-if="options" :id="`${title}-select-input`" :options="options" :multiple="false"
                    v-model="item[id_field]" />
            </b-col>
            <b-col cols="5">
                <span>Quantity</span>
                <b-input v-model="item.quantity" type="number" />
            </b-col>
            <b-col class="d-flex align-items-center" cols="1">
                <b-button variant="danger" size="sm" @click="deleteItem(index)">
                    <slot>
                        <feather-icon icon="Trash2Icon" />
                    </slot>
                </b-button>
            </b-col>
        </b-row>
        <div class="text-center">
            <b-button variant="success" @click="addItem">Add +</b-button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'append-select',
    props: {
        items: { type: Array, required: true },
        options: { type: Array, required: true },
        default_data: { type: Object, default: () => { return {} } },
        title: { type: String, required: true },
        id_field: {type: String, required: true}
    },
    methods: {
        addItem() {
            this.items.push(this.default_data)
        },
        deleteItem(index) {
            this.items.splice(index, 1);
        }
    }
}
</script>