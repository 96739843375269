<template>
  <div>
    <b-modal v-if="item"
      :title="title" hide-footer size="lg" modal-class="modal-primary"
      :no-close-on-backdrop="false"
      v-model="isModalShow"
    >
      <form-generator :model="item" :schema="schema" ref="data_form" />
      <div class="d-flex justify-content-between my-2">
        <b-button variant="secondary" @click="cancel">
          <feather-icon icon="XIcon" class="mr-50" />
          <span class="align-middle">Cancel</span>
        </b-button>
        <b-button type="submit" variant="success" @click="update">
          <b-spinner v-if="isLoading" small />
          <feather-icon v-else icon="CheckIcon" />
          <span class="align-middle ml-50">Update</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'data-update-popup',
  components:{
  },
  props: {
    'updateDataFn': { type: Function },
    defaultData: { type: Object, default() { return {}; } },
    schema: { type: Array, required: true },
    title: { type: String, default: 'Update Data' },
  },
  data(){
    return {
      isLoading: false,
      isModalShow: false,
      item: null,
    }
  },
  methods: {
    startEdit(defaultData = null) {
      if(defaultData === null) { defaultData = this.defaultData }

      this.$nextTick(() => {
        this.item = JSON.parse(JSON.stringify(defaultData));
        this.isModalShow = true;
      })
    },
    async update() {
      let is_valid = this.$refs.data_form.validate();
      if(!is_valid) { return }

      if(!this.updateDataFn) { console.log('updateDataFn notfound'); return }

      this.isLoading = true;
      await this.updateDataFn({...this.item})
      this.isLoading = false;
      this.$emit("updated");
      this.cancel();
    },
    cancel(){
      this.isModalShow = false;
      this.item = null;
    },
  }
}
</script>
