<template>
<b-card class="mb-0">
  <b-card-img v-if="model[field.field]" :src="model[field.field]" :alt="field.label" top />
  <img v-else
    class="d-flex align-items-center justify-content-center w-100 blank_image"
    src="@/assets/images/icons/img-placeholder.jpg"
  />
  <div class="d-flex mt-50">
    <b-form-input :id="`form_input-${field.field}-src`" v-model="model[field.field]" />
    <b-button v-if="!!model[field.field]"
      size="sm" variant="danger" class="ml-50 btn-icon"
      @click="model[field.field] = null"
    >
      <feather-icon
        icon="XIcon"
      />
    </b-button>
  </div>
</b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
  },
  props: {
    model: { type: Object, required: true },
    field: { type: Object, required: true },
  }
})
</script>

