import XLSX from "xlsx"

const self = {
  exportTableElem(table_element, file_name = 'data') {
    let wb = XLSX.utils.table_to_book(table_element);
    XLSX.writeFile(wb, `${file_name}.xlsx`);
  },
  importSheet(file, header = null) {
    if (!file) { return }
    let rows = [];
    const reader = new FileReader();
    let progress = new Promise((resolve, reject) => {
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        // Setup range
        let range = XLSX.utils.decode_range(ws['!ref']);
        // range.s.c = 1;
        if (header) {
          range.s.r = 1;
        }
        let newRange = XLSX.utils.encode_range(range);
        /* Convert array of arrays */
        rows = XLSX.utils.sheet_to_json(ws, { header, range: newRange });
        resolve(rows);
      }
    });
    reader.readAsBinaryString(file);
    return progress;
  },
  exportData(data_list, header = null, file_name = 'data_list') {
    let opts = header ? { origin: 'A2', skipHeader: true } : {};

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(data_list, opts);
    if(header) {
      XLSX.utils.sheet_add_aoa(ws, [header]);
    }
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${file_name}.xlsx`);
  },
};

export default self;
