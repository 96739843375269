export default [
    {
      path: '/blocker',
      component: () => import('./views/Index'),
      name: 'blocker',
      redirect: { name: 'blocker-list' },
      children: [
        {
            path: '',
            name: 'blocker-list',
            component: () => import('./views/List'),
                meta: {
                    resource: 'content',
                },
            },
        {
            path: ':id',
            name: 'blocker-detail',
            component: () => import('./views/Detail'),
                meta: {
                resource: 'auth',
                },
            },
      ],
    },
  ]
  