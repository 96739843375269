<template>
  <b-button :variant="variant" :size="size" :disabled="disabled"
    v-on="$listeners"
  >
    <b-spinner v-if="isLoading" small />
    <feather-icon v-else-if="icon" :icon="icon" />
    <span class="align-middle ml-50"><slot>{{ text }}</slot></span>
  </b-button>
</template>

<script>
export default {
  name: 'LoadingButton',
  props: {
    isLoading: { type: Boolean, default: false },
    size: { type: String, default: 'md' },
    variant: { type: String, default: 'primary' },
    icon: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    text: { type: String, default: 'Add New' }
  },
}
</script>
