<template>
  <div>
    <!-- <b-form-group label="Language" label-for="v-lang"> -->
    <!-- <b-form-select v-model="selected_country" :options="countries" /> -->
    <multi-select :multiple="false" :id="'country_select' + Math.random()"
      :options="countries"
      :allow_empty="false"
      :disabled="disabled"
      v-model="selected_country"
    />
    <!-- </b-form-group> -->
  </div>
</template>

<script>
export default {
  name: "CountrySelect",
  props: {
    country: { type: String, default: "vi" },
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      countries: [],
      selected_country: "vi",
    };
  },
  watch: {
    selected_country: function () {
      this.$emit("selectedCountry", this.selected_country);
    },
  },
  created() {
    this.formatCountry();
  },
  methods: {
    formatCountry() {
      if (this.selected_country != this.country) {
        this.selected_country = this.country;
      }
      this.countries = this.$store.state.country.countries.map((item) => {
        return {
          text: item.name,
          value: item.code,
        };
      });
      this.countries.unshift({text: 'All', value: null})
    },
  },
};
</script>
