
import service from './service'

export default {
  namespaced: true,
  state: {
    countries: [],
  },
  mutations: {
    GET_COUNTRIES(state, countries) {
      state.countries = countries
    }
  },
  actions: {
    async setCountry({ commit }) {
      let response = await service.getAll();
      if (response.data.data) {
        commit("GET_COUNTRIES", response.data.data.list);
      }
    }
  },
  getters: {
    country_options(state){
      return state.countries.map((item) => {
        return {
          text: item.code,
          value: item.code,
        };
      });
    }
  }
}
