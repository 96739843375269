import api from '@/libs/axios'

export default {
  async getAll() {
    let response = await api.get('/admin/countries');
    return response
  },
  async create(payload) {
    const response = await api.post('/admin/countries', payload)
    return response
  },
  async update(payload) {
    let response = await api.put('/admin/countries', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/countries', {
      data: payload,
    })
    return response
  },
  async getList(params) {
    let response = await api.get('/admin/countries', {
      params,
    })
    return response
  },
}
