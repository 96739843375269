<template>
  <b-input-group>
    <date-picker ref="date_picker" :value="value" v-bind="$attrs" v-on="$listeners" :config="allConfig" />
    <b-input-group-append is-text>
      <feather-icon icon="CalendarIcon" />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
  name: 'datetime-picker',
  components: {
    DatePicker: flatPickr
  },
  props: {
    config: { type: Object, default() { return {} } },
    value: { default: null },
  },
  data() {
    return {
      defaultConfig: {
        wrap: true,
        altInput: true,
        altFormat: "d/m/Y",
        defaultHour: 11
      },
    }
  },
  computed: {
    allConfig() {
      return { ...this.defaultConfig, ...this.config }
    },
  },
  mounted(){
    this.$refs.date_picker.fp.setDate(this.value, true);
  }
}
</script>
