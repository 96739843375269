import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { getAccessToken } from './cookie'
import router from '../router'

const axiosIns = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
  timeout: 18000,
  headers: {

  },
})

const noti_types = {
  'SUCCESS': 'pushSuccessNotify',
  'WARN': 'pushWarningNotify',
  'ERROR': 'pushErrorNotify',
  'INFO': 'pushInfoNotify',
}

axiosIns.interceptors.response.use(response => {
  if (['put', 'patch', 'post', 'delete'].includes(response.config.method) && ['SUCCESS', 'DATA'].includes(response.data.type)) {
    store.dispatch('pushSuccessNotify', { text: `successfully ${response.config.method} data` })
  }
  if (response.data.type == 'MESSAGE') {
    store.dispatch(noti_types[response.data.level] || 'pushInfoNotify', { text: `${response.data.key}` })
    if (response.data.key === 'unauthenticated') {
      return router.push({ name: 'auth-login' })
    } else if (response.data.level === 'ERROR') {
      let error = new Error(response.data.key);
      error.data = response;
      throw error;
    }
  }
  return response
}, error => {
  let message = error?.response?.data?.message || 'request timeout!'
  let status_code = error?.response?.status || 'NaN'
  store.dispatch('pushErrorNotify', { text: `${status_code} ${message}` })
  return Promise.reject(error)
})

axiosIns.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers = { Authorization: `Bearer ${getAccessToken()}` }
  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
