export default [
  {
    path: "/map-pool",
    component: () => import("./views/Index"),
    name: "map-pool",
    redirect: { name: "map-pool-list", params: {page: 1} },
    children: [
      {
        path: "page/:page",
        name: "map-pool-list",
        component: () => import("./views/List"),
        meta: {
          resource: "content"
        },
      },
      {
        path: ":id",
        name: "map-pool-detail",
        component: () => import("./views/Detail"),
        meta: {
          resource: "auth"
        }
      }
    ]
  }
];
