export default [
  {
    path: "/reward",
    component: () => import("./views/Index"),
    name: "reward",
    redirect: { name: "reward-list" },
    children: [
      {
        path: "",
        name: "reward-list",
        component: () => import("./views/List"),
        meta: {
          resource: "content"
        }
      },
      {
        path: ":id",
        name: "reward-detail",
        component: () => import("./views/Detail"),
        meta: {
          resource: "auth"
        }
      }
    ]
  }
];
