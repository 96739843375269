<template>
    <vue-multi-select v-if="options"
        :multiple="true"
        :id="id"
        :options="options"
        group-label="groupLabel"
        group-values="groupValues"
        track-by="value" 
        label="text"
        :group-select="true"
        @input="handleGroupSelect"
        :clear-on-select="true"
        v-model="localSelected"
    />
</template>
<script>
import VueMultiSelect from 'vue-multiselect'
export default {
    components: {
        VueMultiSelect
    },
    props: {
        id: {type: String, required: true},
        options: {type: Array, required: true},
        groupSelectedValue: {type: Array, required: true},
    },
    created(){
        this.groupValueInit();
    },
    data(){
        return {
            localSelected: []
        }
    },
    methods: {
        handleGroupSelect(){
            this.$emit('groupSelected', this.localSelected.map((item)=>{return item.value}));
        },
        groupValueInit(){
            this.localSelected = this.groupSelectedValue.map((item)=>{
                let option = this.options[0].groupValues.find((groupItem)=>{return JSON.stringify(groupItem.value) === JSON.stringify(item)})
                return option
            })
        }
    }
}
</script>